@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./static/fonts/Golos-UI/Golos-UI_Regular.css");
@import url("./static/fonts/Golos-UI/Golos-UI_Medium.css");
@import url("./static/fonts/Golos-UI/Golos-UI_Bold.css");
@import url("./static/fonts/DaysOne/DaysOne.css");

@layer base {

    html {
        font-family: GolosUIWebRegular, sans-serif;
    }

    .font-bold {
        @apply font-['GolosUIWebBold']
    }

    .font-medium {
        @apply font-['GolosUIWebMedium']
    }

    h1, .h1 {
        @apply font-['Days_One'] mb-4 text-3xl leading-none tracking-tight text-slate-900 dark:text-white;
    }

    h2, .h2 {
        @apply font-['Days_One'] text-2xl text-slate-800 dark:text-white;
    }

    h3, .h3 {
        @apply font-['Days_One'] text-2xl text-slate-600 dark:text-white;
    }

    h4, .h4 {
        @apply font-['Days_One'] text-xl text-slate-800 dark:text-white;
    }

    h5, .h5 {
        @apply font-['Days_One'] text-lg text-slate-600 dark:text-white;
    }

    h6, .h6 {
        @apply font-['GolosUIWebMedium'] text-lg text-slate-800 dark:text-white;
    }


}

@layer components {
    .badge {
        @apply font-['GolosUIWebMedium'] text-xs mr-1 px-1.5 py-0.5 rounded align-middle;
    }

    .badge-xl {
        @apply font-['Days_One'] text-sm mr-1 px-1.5 py-0.5 rounded;
    }

    .badge-blue {
        @apply bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300;
    }

    .badge-gray {
        @apply bg-slate-100 text-slate-700 dark:bg-slate-600 dark:text-slate-200;
    }

    .badge-red {
        @apply bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300;
    }

    .badge-green {
        @apply bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300;
    }

    .badge-yellow {
        @apply bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300;
    }

    .badge-indigo {
        @apply bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300;
    }

    .badge-purple {
        @apply bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300;
    }

    .badge-pink {
        @apply bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300;
    }
}