@font-face {
    font-family: 'Days One';
    src: url('DaysOne-Regular.eot');
    src: local('Days One'), local('DaysOne-Regular'),
        url('DaysOne-Regular.eot?#iefix') format('embedded-opentype'),
        url('DaysOne-Regular.woff2') format('woff2'),
        url('DaysOne-Regular.woff') format('woff'),
        url('DaysOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

